<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container">
      <div class="booking--detail-container">
        <div class="left--col timeline--wrapper" :class="{ active: isActive }">
          <timeline :timeline="timeline" />
        </div>
        <div class="right--col">
          <div class="booking--detail-content">
            <div class="back--wrapper pt-0 px-0" v-if="$mq === 'xs'">
              <div class="back--button" @click="back">
                <img src="@/assets/img/icons/back-2.svg" />
              </div>
              {{ $t('general.btn.back') }}
            </div>
            <booking-detail-header />
            <listing-info :listing="listing" />
            <div class="row">
              <action class="col-12 col-md-6" />
              <booking-info class="col-12 col-md-6" />
            </div>
            <initiate-tour-rent-section
              :application-uuid="uuid"
              :allowed-actions="allowedActions"
              :booking-info="bookingInfo"
              :type="applicationType"
            />
            <reschedule-tour-section
              :application-uuid="uuid"
              :allowed-actions="allowedActions"
              :booking-info="bookingInfo"
              :type="applicationType"
            />
            <reject-only-tour-section
              :application-uuid="uuid"
              :allowed-actions="allowedActions"
              :booking-info="bookingInfo"
              :type="applicationType"
            />
            <accepted-tour-section :tour-info="tourInfo" />
          </div>
        </div>
      </div>
    </div>
    <reject-booking-modal @actionDone="getMessages" />
    <reject-sell-modal @actionDone="getMessages" />
    <reject-rent-modal @actionDone="getMessages" />
    <handover-booking-modal @actionDone="getMessages" />
    <accept-place-booking-modal @actionDone="getMessages" />
    <accept-place-rent-modal @actionDone="getMessages" />
    <offline-pay-rent-modal @actionDone="getMessages" />
    <accept-booking-req-modal @actionDone="getMessages" />
    <accept-rent-modal @actionDone="getMessages" />
    <reject-booking-req-modal @actionDone="getMessages" />
    <initiate-tour-one-modal />
    <initiate-tour-two-modal />
    <initiate-tour-confirmation-modal @actionDone="getMessages" />
    <reschedule-tour-confirmation-modal @actionDone="getMessages" />
    <accept-tour-modal @actionDone="getMessages" />
    <reject-tour-modal @actionDone="getMessages" />
    <applicant-info-sell-modal @actionDone="getMessages" />
    <confirm-booking-fee-sell-modal @actionDone="getMessages" />
    <input-unique-number-sell-modal />
    <modal-contact />
  </default-layout>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import DefaultLayout from '@/layouts/default';
import Timeline from '@/components/booking/timeline';
import ListingInfo from '@/components/utils/listing-info';
import BookingDetailHeader from '@/components/booking/header';
import Action from '@/components/booking/action';
import BookingInfo from '@/components/booking/booking-info';
import DefaultHeadMixin from '@/mixins/defaultHead';

const RejectBookingModal = () =>
  import('@/components/application-action/modals/booking/reject-modal');
const RejectRentModal = () =>
  import('@/components/application-action/modals/rent-transaction/reject-modal');
const RejectSellModal = () =>
  import('@/components/application-action/modals/sell-transaction/reject-modal');
const HandoverBookingModal = () =>
  import('@/components/application-action/modals/booking/handover-modal');
const AcceptPlaceBookingModal = () =>
  import('@/components/application-action/modals/booking/accept-place-modal');
const AcceptPlaceRentModal = () =>
  import('@/components/application-action/modals/rent-transaction/accept-place-modal');
const OfflinePayRentModal = () =>
  import('@/components/application-action/modals/rent-transaction/offline-pay-modal');
const InitiateTourRentSection = () =>
  import('@/components/application-action/sections/tour-prog/initiate-tour-rent-section');
const RescheduleTourSection = () =>
  import('@/components/application-action/sections/tour-prog/reschedule-tour-section');
const AcceptedTourSection = () =>
  import('@/components/application-action/sections/tour-prog/accepted-tour-section');
const RejectOnlyTourSection = () =>
  import('@/components/application-action/sections/tour-prog/reject-only-tour-section.vue');
const AcceptBookingReqModal = () =>
  import('@/components/application-action/modals/booking-request/accept-modal');
const AcceptRentModal = () =>
  import('@/components/application-action/modals/rent-transaction/accept-modal');
const RejectBookingReqModal = () =>
  import('@/components/application-action/modals/booking-request/reject-modal');
const InitiateTourOneModal = () =>
  import('@/components/application-action/modals/tour-prog/initiate-one-modal');
const InitiateTourTwoModal = () =>
  import('@/components/application-action/modals/tour-prog/initiate-two-modal');
const InitiateTourConfirmationModal = () =>
  import('@/components/application-action/modals/tour-prog/initiate-confirmation-modal');
const RescheduleTourConfirmationModal = () =>
  import('@/components/application-action/modals/tour-prog/reschedule-confirmation-modal');
const RejectTourModal = () =>
  import('@/components/application-action/modals/tour-prog/reject-modal');
const AcceptTourModal = () =>
  import('@/components/application-action/modals/tour-prog/accept-modal');
const ApplicantInfoSellModal = () =>
  import('@/components/application-action/modals/sell-transaction/applicant-info-modal');
const InputUniqueNumberSellModal = () =>
  import('@/components/application-action/modals/sell-transaction/input-unique-number-modal');
const ConfirmBookingFeeSellModal = () =>
  import('@/components/application-action/modals/sell-transaction/confirm-booking-fee-modal');
import ModalContact from '@/components/utils/modals/contact-modal';

export default {
  name: 'booking-detail',
  mixins: [DefaultHeadMixin, HelperMixin],
  components: {
    DefaultLayout,
    ModalContact,
    RejectRentModal,
    ApplicantInfoSellModal,
    InputUniqueNumberSellModal,
    ConfirmBookingFeeSellModal,
    Timeline,
    BookingDetailHeader,
    ListingInfo,
    Action,
    BookingInfo,
    RejectSellModal,
    RejectBookingModal,
    HandoverBookingModal,
    AcceptPlaceBookingModal,
    AcceptPlaceRentModal,
    OfflinePayRentModal,
    InitiateTourRentSection,
    AcceptedTourSection,
    RescheduleTourSection,
    RejectOnlyTourSection,
    AcceptBookingReqModal,
    AcceptRentModal,
    RejectBookingReqModal,
    InitiateTourOneModal,
    InitiateTourTwoModal,
    InitiateTourConfirmationModal,
    RescheduleTourConfirmationModal,
    AcceptTourModal,
    RejectTourModal,
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.global.baseUrl,
      loading: (state) => state.v2.booking.detail.isLoading,
      tourInfo: (state) => state.v2.booking.detail.tourInfo,
      applicationType: (state) => state.v2.booking.detail.type,
      uuid: (state) => state.v2.booking.detail.uuid,
      listing: (state) => state.v2.booking.detail.listing,
      timeline: (state) => state.v2.booking.detail.timeline,
      bookingInfo: (state) => state.v2.booking.detail.bookingInfo,
      allowedActions: (state) => state.v2.booking.detail.allowedActions,
      isActive: (state) => state.v2.booking.timelineSidebar,
    }),
    breadcrumbs() {
      if (!this.bookingInfo) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('dashboard.myBooking'),
          exact: true,
          to: '/dashboard/mybooking',
        },
        {
          text: `${this.$t('booking.number')} ${
            this.bookingInfo && this.bookingInfo.order_number
              ? this.bookingInfo.order_number
              : this.uuid
          }`,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },

  async asyncData({ store, redirect, route }) {
    store.commit('v2/booking/detail/RESTORE_INITIAL_STATE');

    const response = await store.dispatch('v2/booking/detail/checkAccessAllowed', {
      type: route.query.type,
      uuid: route.query.uuid,
    });
    if (!response) redirect('/');
    store.commit('v2/booking/detail/SET_TYPE', route.query.type);
    store.commit('v2/booking/detail/SET_UUID', route.query.uuid);
  },
  watch: {
    async '$route.query.uuid'() {
      this.$store.dispatch('v2/booking/detail/getData');
    },
  },
  methods: {
    async getMessages() {
      const result = await this.$store.dispatch('v2/booking/detail/getData');
      if (result !== null) {
        if (result.changeUuid) {
          this.updateUrlQueryNoRefresh(
            {
              type: this.applicationType,
              uuid: this.uuid,
            },
            this.$route,
          );
        }
      }
    },
    back() {
      this.$router.push('/dashboard/mybooking');
    },
  },
  async mounted() {
    const result = await this.$store.dispatch('v2/booking/detail/getData');
    if (result !== null) {
      if (result.changeUuid) {
        this.updateUrlQueryNoRefresh(
          {
            type: this.applicationType,
            uuid: this.uuid,
          },
          this.$route,
        );
      }
    }
  },
};
</script>
