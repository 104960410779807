<template>
  <section class="booking--detail-header">
    <div v-if="customAgreementData">
      <custom-agreement-section />
      <hr />
    </div>
    <div class="status">
      <div class="left--col title--text">Status</div>
      <div class="right--col">
        <div style="width: fit-content">
          <div class="label--container">
            <div class="label">{{ guideline }}</div>
          </div>
          <button
            class="btn btn-primary--outline btn-block mb-1"
            v-if="$mq === 'xs'"
            @click="openTimeline()"
          >
            Lihat Detail Status
          </button>
          <how-to-pay-booking-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
          <how-to-pay-sell-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
          <how-to-pay-rent-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
          <accept-sell-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
            :booking-info="bookingInfo"
          />
          <accept-rent-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
            :booking-info="bookingInfo"
          />
          <confirm-booking-fee-sell-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :booking-info="bookingInfo"
          />
          <pay-booking-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
          />
          <pay-rent-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
          />
          <pay-rent-btn-section
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
          />
          <pay-sell-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
          />
          <sign-booking-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
          />
          <sign-sell-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
            :order-number="orderNumber"
            :booking-info="bookingInfo"
          />
          <handover-booking-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
          <accept-booking-req-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
          <accept-tour-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :booking-info="bookingInfo"
            :type="applicationType"
          />
          <accept-place-booking-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
          <accept-place-rent-btn
            :application-uuid="uuid"
            :allowed-actions="allowedActions"
            :type="applicationType"
          />
        </div>
      </div>
    </div>
    <div class="title--text">
      {{ $t('booking.detail.propertyHMessage') }}
    </div>
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

import { mapState } from 'vuex';

const CustomAgreementSection = () =>
  import('@/components/booking/partials/custom-agreement-section');
const AcceptBookingReqBtn = () =>
  import('@/components/application-action/buttons/booking-request/accept-btn');
const AcceptSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/accept-btn');
const AcceptRentBtn = () =>
  import('@/components/application-action/buttons/rent-transaction/accept-btn');
const ConfirmBookingFeeSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/confirm-booking-fee-btn');

const HowToPayBookingBtn = () =>
  import('@/components/application-action/buttons/booking/how-to-pay-btn');
const HowToPaySellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/how-to-pay-btn');
const HowToPayRentBtn = () =>
  import('@/components/application-action/buttons/rent-transaction/how-to-pay-btn');
const PayBookingBtn = () => import('@/components/application-action/buttons/booking/pay-btn');
const PayRentBtn = () => import('@/components/application-action/buttons/rent-transaction/pay-btn');
const PayRentBtnSection = () =>
  import('@/components/application-action/buttons/rent-transaction/pay-btn-section');
const PaySellBtn = () => import('@/components/application-action/buttons/sell-transaction/pay-btn');
const SignBookingBtn = () => import('@/components/application-action/buttons/booking/sign-btn');
const SignSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/sign-btn');
const HandoverBookingBtn = () =>
  import('@/components/application-action/buttons/booking/handover-btn');
const AcceptPlaceBookingBtn = () =>
  import('@/components/application-action/buttons/booking/accept-place-btn');
const AcceptPlaceRentBtn = () =>
  import('@/components/application-action/buttons/rent-transaction/accept-place-btn');
const AcceptTourBtn = () => import('@/components/application-action/buttons/tour-prog/accept-btn');
export default {
  name: 'booking-detail-header',
  mixins: [HelperMixin],
  components: {
    SignSellBtn,
    AcceptBookingReqBtn,
    AcceptPlaceRentBtn,
    CustomAgreementSection,
    HowToPayBookingBtn,
    AcceptTourBtn,
    PayBookingBtn,
    SignBookingBtn,
    AcceptPlaceBookingBtn,
    HandoverBookingBtn,
    PaySellBtn,
    HowToPaySellBtn,
    AcceptSellBtn,
    AcceptRentBtn,
    ConfirmBookingFeeSellBtn,
    PayRentBtn,
    HowToPayRentBtn,
    PayRentBtnSection,
  },
  computed: {
    ...mapState({
      allowedActions: (state) => state.v2.booking.detail.allowedActions,
      uuid: (state) => state.v2.booking.detail.uuid,
      applicationType: (state) => state.v2.booking.detail.type,
      orderNumber: (state) => state.v2.booking.detail.orderNumber,
      guideline: (state) => state.v2.booking.detail.guideline,
      customAgreementData: (state) => state.v2.booking.detail.customAgreementData,
      bookingInfo: (state) => state.v2.booking.detail.bookingInfo,
    }),
    // checkBankAccountInbox: {
    //   get() {
    //     return this.$store.state.v2.profile.checkBankAccountInbox;
    //   },
    //   set(val) {
    //     this.$store.commit('v2/profile/SET_CHECK_BANK_ACC_INBOX', val);
    //   },
    // },
  },
  methods: {
    openTimeline() {
      this.$store.commit('v2/booking/SET_TIMELINE_SIDEBAR', true);
    },
  },
};
</script>
