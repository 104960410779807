<template>
  <section class="booking--timeline" v-if="timeline">
    <div class="back--wrapper" v-if="$mq === 'xs'">
      <div class="back--button" @click="close()">
        <img src="@/assets/img/icons/back-2.svg" />
      </div>
      Detail Status
    </div>
    <div
      class="wrapper"
      :class="{
        cancelled: item.timestamp && item.type === 'CANCELED',
        'on-going': item.timestamp && item.type === 'ON_GOING',
        completed: item.timestamp && item.type === 'COMPLETED',
      }"
      v-for="(item, index) in timeline"
      :key="index"
    >
      <div
        class="indicator"
        :class="{
          'cancelled--indicator':
            index + 1 < timeline.length &&
            timeline[index + 1].timestamp &&
            timeline[index + 1].type === 'CANCELED',
          'on-going--indicator':
            index + 1 < timeline.length &&
            timeline[index + 1].timestamp &&
            timeline[index + 1].type === 'ON_GOING',
          'completed--indicator':
            index + 1 < timeline.length &&
            timeline[index + 1].timestamp &&
            timeline[index + 1].type === 'COMPLETED',
        }"
      >
        <div class="circle">{{ index + 1 }}</div>
      </div>
      <div class="content px-5">
        <div class="title--text">{{ item.status_name }}</div>
        <div class="subtitle">{{ item.status_message }}</div>
        <div class="timestamp" v-if="item.timestamp">
          {{ $date.format(item.timestamp, 'dd MMMM yyyy HH:mm') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['timeline'],
  computed: {
    isLastItem(itemList, index) {
      if (itemList && index < itemList.length - 1) {
        if (!itemList[index + 1].timestamp) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    close() {
      this.$store.commit('v2/booking/SET_TIMELINE_SIDEBAR', false);
    },
  },
};
</script>
