<template>
  <section class="booking--detail-action" v-if="bookingInfo">
    <div class="title--text">
      {{
        forApplicant
          ? $t('booking.modal.customAgreement.owner')
          : $t('booking.modal.customAgreement.applicant')
      }}
    </div>
    <div class="action--container">
      <div class="top--part">
        <img :src="!forApplicant ? bookingInfo.applicant.photo : listing.owner.photo" />
        <span>{{ !forApplicant ? bookingInfo.applicant.name : listing.owner.name }}</span>
      </div>
      <download-agreement-booking-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <download-fkp-sell-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <see-applicant-info-sell-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <reject-booking-request-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <reject-rent-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <reject-booking-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <reject-sell-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :type="applicationType"
      />
      <reject-booking-tour-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :booking-info="bookingInfo"
        :type="applicationType"
      />
      <contact-booking-request-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :booking-info="bookingInfo"
        :type="applicationType"
      />
      <contact-booking-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :booking-info="bookingInfo"
        :type="applicationType"
      />
      <contact-sell-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :booking-info="bookingInfo"
        :type="applicationType"
      />
      <contact-rent-btn
        :application-uuid="uuid"
        :allowed-actions="allowedActions"
        :booking-info="bookingInfo"
        :type="applicationType"
      />
      <!--      <div class="btn btn-primary&#45;&#45;inverse">-->
      <!--        <img class="mr-2" src="@/assets/img/icons/cancel.svg" />-->
      <!--        <span>Batalkan Booking</span>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
const ContactBookingBtn = () =>
  import('@/components/application-action/buttons/booking/contact-btn');
const ContactBookingRequestBtn = () =>
  import('@/components/application-action/buttons/booking-request/contact-btn');
const ContactSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/contact-btn');
const ContactRentBtn = () =>
  import('@/components/application-action/buttons/rent-transaction/contact-btn');
const RejectBookingRequestBtn = () =>
  import('@/components/application-action/buttons/booking-request/reject-btn');
const RejectRentBtn = () =>
  import('@/components/application-action/buttons/rent-transaction/reject-btn');
const RejectBookingTourBtn = () =>
  import('@/components/application-action/buttons/tour-prog/reject-booking-btn');
const RejectBookingBtn = () => import('@/components/application-action/buttons/booking/reject-btn');
const RejectSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/reject-btn');
const SeeApplicantInfoSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/see-applicant-info-btn');
const DownloadAgreementBookingBtn = () =>
  import('@/components/application-action/buttons/booking/download-agreement-btn');
const DownloadFkpSellBtn = () =>
  import('@/components/application-action/buttons/sell-transaction/download-fkp-btn');
export default {
  components: {
    RejectRentBtn,
    ContactBookingRequestBtn,
    ContactSellBtn,
    ContactRentBtn,
    RejectBookingRequestBtn,
    ContactBookingBtn,
    DownloadFkpSellBtn,
    RejectBookingTourBtn,
    RejectBookingBtn,
    DownloadAgreementBookingBtn,
    RejectSellBtn,
    SeeApplicantInfoSellBtn,
  },
  computed: {
    ...mapState({
      bookingInfo: (state) => state.v2.booking.detail.bookingInfo,
      listing: (state) => state.v2.booking.detail.listing,
      allowedActions: (state) => state.v2.booking.detail.allowedActions,
      applicationType: (state) => state.v2.booking.detail.type,
      uuid: (state) => state.v2.booking.detail.uuid,
    }),
    forApplicant() {
      return this.$store.state.global.user
        ? this.bookingInfo.applicant_id == this.$store.state.global.user.id
        : false;
    },
  },
};
</script>
